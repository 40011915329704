<template>
	<div style="width: 100vw;height: 100vh;">
		<el-container class="h-100 w-100">
			<!-- <el-aside class="d-flex flex-column h-100 bg-home" style="width: 10vw;min-width: 160px;"> -->
				<div style="background-color: #1c95d4;">
					<el-menu :default-active="defaultactive" class="el-menu-vertical-demo" background-color="#1c95d4"
						text-color="#fff" active-text-color="#1c95d4" :unique-opened="false" :router="false"
						@select="" :collapse="imgshow">
						<el-menu-item-group>
							<div class="text-center title-font">
								<div v-if="!imgshow" style="font-size: 18px;">
									托付保集成平台
								</div>
								<div v-if="imgshow">
									<img style="width: 25px;height: 25px;" src="../assets/images/logo.png" alt="">
								</div>
							</div>
						</el-menu-item-group>
						<el-submenu :index="String(index)" v-for="(item,index) in systemData" :key="index">
							<template slot="title">
								<i :class="iconarr[index]" style="color: #fff;"></i>
								<span>{{item.groupName}}</span>
							</template>
							<el-menu-item-group>
								<el-menu-item :index="item1.homepage" v-for="(item1,index1) in item.systemDetails"
									:key="index1" @click="tourl(item1)">
									<div class="d-flex align-items-center">
										<div>
											<div
												style="background-color: #fff;width: 7px;height: 7px;border-radius: 50%;">
											</div>
										</div>
										<div style="margin-left: 10px;">
											{{item1.name}}
										</div>
									</div>
								</el-menu-item>
							</el-menu-item-group>
						</el-submenu>
					</el-menu>
				</div>
			<!-- </el-aside> -->
			<el-container>
				<el-header style="background-color: #1c95d4;">
					<div class="d-flex flex-row align-items-center justify-content-between"
						style="background-color: #1c95d4;height: 60px;padding: 0 30px;">
						<div class="d-flex flex-row align-items-center" style="cursor: pointer;">
							<img src="../assets/images/left.png" style="width: 25px;height: 25px;" alt=""
								v-if="!imgshow" @click="imgshow = !imgshow">
							<img src="../assets/images/right.png" style="width: 25px;height: 25px;" alt=""
								v-if="imgshow" @click="imgshow = !imgshow">
						</div>
						<div class="d-flex flex-row align-items-center">
							<div class="d-flex align-items-center"
								style="border: 1px solid #1c95d4;border-radius: 50%;">
								<img src="../assets/images/touxiang.png" width="35px" height="35px"
									style="border-radius: 50%;" alt="">
							</div>
							<div class="d-flex align-items-center" style="margin-left: 10px;">
								<el-dropdown trigger="click" @command="clickname">
									<span class="el-dropdown-link" style="font-size: 14px;color: #fff;">
										{{userData.realName}}<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown" class="text-center">
										<el-dropdown-item command="toinfo">查看个人信息</el-dropdown-item>
										<el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
										<el-dropdown-item command="logout">退出登录</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
					</div>
				</el-header>
				<el-main>
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
		<el-dialog title="密码修改" :visible.sync="dialogVisible"  :before-close="handleClose">
		
				<el-form size="small" :model="resetForm" :rules="resetFormRules" ref="resetForm" label-width="100px" >
					<el-form-item label="旧密码：" prop="password" style="line-height: 20px;width: 500px;">
						<el-input type="password" v-model="resetForm.password" auto-complete="off" clearable
							show-password style="width: 400px;"></el-input>
					</el-form-item>
					<el-form-item label="新密码：" prop="newpwd"  style="line-height: 20px;width: 500px;">
						<el-input type="password" v-model="resetForm.newpwd" auto-complete="off" clearable
							show-password style="width: 400px;"></el-input>
					</el-form-item>
					<el-form-item label="确认密码：" prop="newpwd1"  style="line-height: 20px;width: 500px;">
						<el-input type="password" v-model="resetForm.newpwd1" auto-complete="off" clearable
							show-password style="width: 400px;"></el-input>
					</el-form-item>
				</el-form>
				<div style=" text-align:center;">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button @click="reset('resetForm')">重置</el-button>
					<el-button type="primary" @click.native.prevent="toAmend">确 定</el-button>
				</div>
			
		</el-dialog>
	</div>
</template>

<script>
	import 'lib-flexible'
	import Cookies from 'js-cookie'
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请输入新密码"));
				} else if (value.toString().length < 6 || value.toString().length > 15) {
					callback(new Error("密码长度为6-15位"));
				} else {
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请再次输入密码"));
				} else if (value !== this.resetForm.newpwd) {
					callback(new Error("两次输入密码不一致!"));
				} else {
					callback();
				}
			};
			return {
				iconarr: ['el-icon-s-marketing', 'el-icon-s-data', 'el-icon-menu', 'el-icon-s-management',
					'el-icon-s-cooperation', 'el-icon-share', 'el-icon-s-opportunity', 'el-icon-s-tools',
					'el-icon-s-help',
				],
				page: 1,
				pageSize: 100,
				systemData: [],
				userData: {},
				nowdate: '',
				squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
				defaultactive: '',
				imgshow: false,
				dialogVisible: false,
				resetForm: {
					//传给后台所需要的参数
					newpwd1: "",
					password: "",
				},
				resetFormRules: {
					password: [{
						required: true,
						message: "请输入旧密码",
						trigger: 'blur'
					}],
					newpwd: [{
						required: true,
						validator: validatePass,
						trigger: 'blur'
					}],
					newpwd1: [{
						required: true,
						validator: validatePass2,
						trigger: "blur"
					}]
				}
			}
		},
		mounted() {
			let that = this
			this.getSystemList()
			this.getuserinfor()
		},
		destroyed() {

		},
		methods: {
			// 跳转到url
			tourl(item) {
				Cookies.set('windowloacl', 'create', {
					domain: '.tofobao.com'
				})
				let that = this
				Cookies.remove('routers', {
					domain: '.tofobao.com'
				})
				Cookies.remove('tabactive', {
					domain: '.tofobao.com'
				})
				window.open(item.homepage, "_blank");
			},
			// 获取系统列表
			getSystemList() {
				let that = this
				//获取系统列表
				this.$axios.get('rbac/v1/getSystemGroupListBy?abbr=&page=' + this.page + '&pageSize=' + this
					.pageSize + '&visible=true' + '&mySystem=true').then(res => {
					if (res.data.code == '1') {
						that.systemData = res.data.data
					}
				})
			},
			// 确认是否登陆
			istologin() {
				let backlogin = Cookies.get('tologin', {
					domain: '.tofobao.com'
				})
				if (backlogin == '2') {
					this.$message.info('令牌已过期!')
					Cookies.remove('tologin', {
						domain: '.tofobao.com'
					})
					Cookies.remove('token', {
						domain: '.tofobao.com'
					})
					Cookies.remove('userMeaList', {
						domain: '.tofobao.com'
					})
					Cookies.remove('rbacMeaList', {
						domain: '.tofobao.com'
					})
					Cookies.remove('projectMeaList', {
						domain: '.tofobao.com'
					})
					Cookies.remove('userData', {
						domain: '.tofobao.com'
					})
					Cookies.remove('userId', {
						domain: '.tofobao.com'
					})
					sessionStorage.removeItem('token')
					this.$router.push({
						path: 'Login'
					})
				}
			},
			//确认修改
			toAmend() {
				let userDataCookies = JSON.parse(Cookies.get('userData'))
				this.$refs.resetForm.validate(valid => {
					if (valid) {
						this.$axios.post('user/v1/updateUserPasswordById', {
								// id : this.userData.id,
								id: userDataCookies.id,
								newPassword: this.resetForm.newpwd1,
								oldPassword: this.resetForm.password,
							}).then(res => {
								console.log(res)
								if (res.data.code != 1) {
									return false;
								}
								if (res.data.code == 1) {
									this.$message.success("修改成功,3秒后跳转到登录页！");
									setTimeout(() => {
										this.$router.push({
											path: 'Login'
										})
									}, 3000);
									Cookies.remove('token', {
										domain: '.tofobao.com'
									})
									Cookies.remove('userMeaList', {
										domain: '.tofobao.com'
									})
									Cookies.remove('rbacMeaList', {
										domain: '.tofobao.com'
									})
									Cookies.remove('projectMeaList', {
										domain: '.tofobao.com'
									})
									Cookies.remove('userData', {
										domain: '.tofobao.com'
									})
									Cookies.remove('userId', {
										domain: '.tofobao.com'
									})
									sessionStorage.removeItem('token')
									sessionStorage.removeItem('userdata')
								}
								ic
							})
							.catch(() => {});
					}
				});
			},
			// 获取用户信息
			getuserinfor() {
				let that = this
				this.$axios.get('user/v1/getCurrentUserDetail').then(res => {
					if (res.data.code == '1') {
						sessionStorage.setItem('userdata', JSON.stringify(res.data.data))
						Cookies.set('userData', JSON.stringify(res.data.data), {
							domain: '.tofobao.com'
						})
						Cookies.set('userId', res.data.data.id, {
							domain: '.tofobao.com'
						})
						that.userData = res.data.data
					}
				})
			},
			// 点击名字
			clickname(e) {
				let that = this
				if (e == 'toinfo') {
					this.$message.info('暂未开发，请耐心等待')
				} else if (e == "logout") {
					this.$confirm('确认退出？').then(_ => {
						Cookies.remove('token', {
							domain: '.tofobao.com'
						})
						Cookies.remove('userMeaList', {
							domain: '.tofobao.com'
						})
						Cookies.remove('rbacMeaList', {
							domain: '.tofobao.com'
						})
						Cookies.remove('projectMeaList', {
							domain: '.tofobao.com'
						})
						Cookies.remove('userData', {
							domain: '.tofobao.com'
						})
						Cookies.remove('userId', {
							domain: '.tofobao.com'
						})
						sessionStorage.removeItem('token')
						sessionStorage.removeItem('userdata')
						this.$router.push({
							path: 'Login'
						})
						this.$message({
							message: '退出成功！',
							type: 'success',
							debugger: 0
						});
						Cookies.get('returnLogin', 'one', {
							domain: '.tofobao.com'
						})
					}).catch(_ => {});
				} else if (e == "changePassword") {
					this.dialogVisible = true;
					this.resetForm = {};
			
				}
			},
			reset(formName) {
				this.$refs[formName].resetFields();
			},
			//弹出修改密码框
			handleClose(done) {
				// done();
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
		}
	}
</script>

<style scoped="scoped">
	*,
	html,
	body,
	p {
		/* color: white; */
		/* font-family: Microsoft YaHei; */
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}

	.el-menu-item {
		font-size: 15px !important;
		/* background-color: red; */
		
	}

	/deep/.el-submenu__title {
		font-size: 15px !important;
		height: 40px;
		line-height: 40px;
	}
	/deep/.el-submenu .el-menu-item{
		height: 40px;
		line-height: 40px;
	}
	/deep/.el-submenu__title i{
		color: #fff;
	}
	/deep/.el-form-item__error{
		    color: #F56C6C;
		    font-size: 12px;
		    line-height: 1;
		    padding-top: 4px;
		    position: absolute;
		    top:60%;
		    left: 0;
	}
	/deep/.el-dialog {
	    position: relative;
	    margin: 0 auto 50px;
	    background: #FFF;
	    border-radius: 2px;
	    box-shadow: 0 1px 3px rgba(0,0,0,.3);
	    box-sizing: border-box;
	    width: 600px;
	}
	.title-font {
		color: white;
		/* font-weight: 600; */
		font-size: calc(100vw * 20 / 1920);
		/* letter-spacing: 4px; */
		padding: 15px 0 15px 0;
		background-color: #1c95d4;
	}

	.el-menu {
		border-right: 0 !important;
	}

	.el-main {
		padding: 0 !important;
		background: #ffffff !important;
	}

	.el-header {
		padding: 0 !important;
	}

	/* .bg-home {
		background-color: #1c95d4;
	} */

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		min-width: 160px;
		min-height: 400px;
	}
	/deep/.el-submenu .el-menu-item{
		min-width: 0;
	}
	.el-menu-item.is-active {
		background-color: #fff !important;
	}
	.el-menu-item:hover{
		background-color: #bfe0f3;
	}
	/deep/.el-menu-item-group__title {
		padding: 0;
	}
</style>
